import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts'

const META = {
  TITLE: 'MisterGoodBeer - Perdu dans le monde merveilleux des bars à bières?',
  DESCRIPTION: 'Aller, MisterGoodBeer va t\'aider à trouver la sortie',
  KEYWORDS: 'MisterGoodBeer bières pas chères bars pas chers happy hour Paris Marseille Lyon Bordeaux Toulouse Lille Montpellier Strasbourg Nantes',
}

const APPLE_DOWNLOAD_URL = 'https://itunes.apple.com/fr/app/mistergoodbeer/id717953901'
const GOOGLE_PLAY_DOWNLOAD_URL = 'https://play.google.com/store/apps/details?id=com.mistergoodbeer.mistergoodbeer'

const schemaOrgJSONLD = [
  {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: 'https://www.mistergoodbeer.com',
    name: META.TITLE,
    description: META.DESCRIPTION,
    keywords: META.KEYWORDS,
    inLanguage: 'fr',
  },
  {
    '@context': 'http://schema.org',
    '@type': 'MobileApplication',
    name: META.TITLE,
    installUrl: APPLE_DOWNLOAD_URL,
    keywords: META.KEYWORDS,
    inLanguage: 'en,fr',
    operatingSystem: 'iOS',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.4',
      ratingCount: '21',
    },
  },
  {
    '@context': 'http://schema.org',
    '@type': 'MobileApplication',
    name: META.TITLE,
    installUrl: GOOGLE_PLAY_DOWNLOAD_URL,
    keywords: META.KEYWORDS,
    inLanguage: 'en,fr',
    operatingSystem: 'Android',
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: '4.6',
      ratingCount: '431',
    },
  },
]

const NotFoundPage = () => (
  <Layout
    seoProps={{
      title: META.TITLE,
      description: META.DESCRIPTION,
      keywords: META.KEYWORDS,
      image: '/img/mistergoodbeer-application.png',
      imageAlt: 'Les applications iPhone et Android de MisterGoodBeer pour trouver les meilleurs bars',
      schemaOrgJSONLD,
    }}
  >

    <section className="section">
      <div className="container">
        <div className="content">
          <h1 className="title">
            Cette route n'existe pas...
          </h1>
          <p>
            ... heureusement, la route de la soif continue
            <Link to="/">
              {' ici'}
            </Link>
            . Pardonne le site, il a dû prendre une bière de trop.
          </p>
        </div>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
